import { useState } from 'react'
import styles from './styles.module.css'
import _ from 'lodash'

const TagsInput = ({ values, onChange, onChangeItem, onDeleteItem, type = '', canEdit = false, placeholder = '' }) => {
  const [isEditing, setIsEditing] = useState(null);
  const [tags, setTags] = useState(values);

  const handleDoubleClick = (index) => {
    if (canEdit) {
      setIsEditing(index);
    }
  }

  const handleChangeTagItem = (e, index) => {
    const newValues = [...tags];
    newValues[index] = e.target.value;
    setTags(newValues);
    // onChange(newValues);
  };

  const handleBlurTagItem = (e, index) => {
    const oldValue = values[index];
    const newValue = tags[index];
    
    setIsEditing(false);

    if (oldValue !== newValue) {
      onChange([...tags]);
      onChangeItem({
        oldValue,
        newValue
      });
    }
  }

  const handleKeyDownTagItem = (e, index) => {
    if (e.key === 'Enter') {
      const oldValue = values[index];
      const newValue = tags[index];
      setIsEditing(false);

      if (oldValue !== newValue) {
        onChange([...tags]);
        onChangeItem({
          oldValue,
          newValue
        });
      }
    }
  }

  const handleKeyDown =  _.debounce((e) => {
    const value = e.target.value
    if (e.key === 'Enter') {
      e.preventDefault()
      if (!value.trim()) return
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (type === 'email' && !regex.test(value)) return
      const newValues = [...tags, value]
      setTags(newValues)
      onChange(newValues)
      e.target.value = ''
    }
    // if (e.key === 'Backspace' && !value) {
    //   if (tags.length < 2) return
    //   tags.pop()
    //   const newValues = [...tags]
    //   setTags(newValues)
    //   onChange(newValues)
    // }
  }, 200)

  function removeTag(index) {
    let newValues = [...tags];
    const deleteValue = newValues[index];
    newValues = newValues.filter((el, i) => i !== index)
    setTags(newValues)
    onChange(newValues)
    if (typeof onDeleteItem === 'function') {
      onDeleteItem(deleteValue);
    }
  }

  return (
    <div className={styles['tags-input-container']}>
      {tags.map((tag, index) => (
        <div className={styles['tag-item']} key={index}>
          {isEditing === index ? (
            <input
              type="text"
              className={styles['tags-item-input']}
              autoFocus
              onChange={(e) => handleChangeTagItem(e, index)}
              onBlur={(e) => handleBlurTagItem(e, index)}
              onKeyDown={(e) => handleKeyDownTagItem(e, index)}
              value={tag}
            />
          ) : (
            <>
              <span className="text" onDoubleClick={() => handleDoubleClick(index)}>
                {tag}
              </span>
              <span className={styles.close} onClick={() => removeTag(index)}>
                &times;
              </span>
            </>
          )}
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        type="text"
        className={styles['tags-input']}
        placeholder={placeholder}
      />
    </div>
  )
}

export default TagsInput
